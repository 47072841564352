import React from "react";
import useNotifyHintNudge from "../../../../hooks/useNotifyHintNudge";
import {HintQuestionModal} from "../../../../components/components";
import appContent from "../../../../markdown/app-content";
import useHintQuestionsFlow from "../../../../hooks/useHintQuestionsFlow";
import {splitMinutes} from "../../../../utilities/dates";

const HintNudgeModal = ({}) => {
  const {getHintPenalty, askTakeHint} = useHintQuestionsFlow();
  const {show, hideModal} = useNotifyHintNudge();

  const penalty = getHintPenalty();
  const modalContent = penalty > 0 ?
    appContent.inGame.hints.hintNudgeContentWithPenalty(splitMinutes(penalty)) :
    appContent.inGame.hints.hintNudgeContentWithoutPenalty;

  return <HintQuestionModal
    buttons={[
      {
        id: 'no',
        text: 'No',
        onClick: () => hideModal(),
      },
      {
        id: 'yes',
        text: 'Yes',
        onClick: () => {
          askTakeHint({isLiveHint: false});
          hideModal();
        },
        countdownSeconds: 3,
      },
    ]}
    show={show}
    title={appContent.inGame.hints.hintNudgeTitle}
    content={modalContent}
    onHide={hideModal}
  />;
};

export default HintNudgeModal;
